body {
  margin: 0;
}
* {
  box-sizing: border-box;
}

@font-face {
	font-family: "q_bold";
	src: url("../Fonts/Quicksand-Bold.ttf");
}
@font-face {
	font-family: "q_medium";
	src: url("../Fonts/Quicksand-Medium.ttf");
}
@font-face {
	font-family: "q_light";
	src: url("../Fonts/Quicksand-Light.ttf");
}
@font-face {
	font-family: "q_regular";
	src: url("../Fonts/Quicksand-Regular.ttf");
}
@font-face {
	font-family: "q_semibold";
	src: url("../Fonts/Quicksand-SemiBold.ttf");
}

* {
  font-family: "q_regular"; 
}


:root {
  --bg:#f59209;
}

table th {
	padding: 5px 10px !important;
	/* background: var(--bg) !important; */
}
.form_table {
	background: #fff;
	padding: 15px 0px 0 0;
	border-radius: 4px;
	margin: 12px 0 0 0;
}
table td {
	padding: 8px 10px !important;
	font-size: 13px;
	font-family: "q_medium";
}
p.action_btn {
	margin: 0;
	padding: 0 8px;
	cursor: pointer;
}
p.action_btn.edit {
	color: #1677ff;
	
}
p.action_btn.delete {
	color: #ff4d4f;
	
}

.ant-form-item {
	margin: 0 0 13px !important;
}
.ant-form-item-label {
	padding: 0 0 6px !important;
}
.ant-modal-header {
	margin: 0 0 15px !important;
}
.switch_btn {
	display: flex !important;
}
.switch_btn .ant-row {
	display: flex !important;
	flex-direction: row !important;
	flex-flow:nowrap !important;
	align-items: center;
}

.switch_btn .ant-col {
	padding: 0 !important;
}
.switch_btn label {
	width: 85px !important;
}
.page_back_align {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}
.go_back {
	cursor: pointer;
	font-family: "q_bold";
}
.edit_option {
	width: 100%;
	display: inline-block;
	text-align: right;
}

.ant-table-measure-row {
	display: none ;
}

.ant-drawer .ant-drawer-body {
  padding: 0 !important;
}
.suspense_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.suspense_wrap_com {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.form_table_filter {
	width: 100%;
	margin: 0 0 15px;
	padding: 0 15px;
}




/* @media screen and (max-width:1200px) {
	.ant-layout-sider {
		display: none;
	}
} */

.custom-input {
	background-color: rgb(224, 219, 219);
	border-radius: 5px;
	padding: 10px;
	border: 1px solid ;
  }
  
  .custom-input:hover {
	background-color: rgb(225, 231, 231);
  }
  
  .custom-input:focus {
	
	border: 1px solid rgb(21, 104, 187); 
  }

